import { SiteWrapper } from "../components/SiteWrapper";
import React from "react";
import Testimonials from "../components/testimonials";
import OurClients from "../page-components/testimonials/OurClients/ourClients";
import Seo from "../components/seo";
import { ACTIVE_PAGE } from "../components/nav/utils";
import { MainContainer, Wrapper } from "../page-components/testimonials/styles";

export default function TestimonialsPage() {
  return (
    <SiteWrapper activeItem={ACTIVE_PAGE.TESTIMONIALS}>
      <Seo
        title="Testimonials | Robotic Imaging"
        description="Construction Imaging, LiDAR Scanning, Drone Photography, and other imaging services. Great service and competitive prices."
      />
      <header className="site__header"></header>
      <MainContainer>
        <Wrapper>
          <OurClients />
          <Testimonials className={""} />
        </Wrapper>
      </MainContainer>
    </SiteWrapper>
  );
}
