import React, { useState, useEffect } from "react";
import { useIsJsEnabled } from "gatsby-plugin-js-fallback";
import VisiblitySensor from "react-visibility-sensor";

// const IMG_WIDTH = 120;

export default function OurClients() {
  const isJsEnabled = useIsJsEnabled();

  return (
    <div className="our-clients">
      {/** Spinner */}
      <h3 className="our-clients__header">We've digitized</h3>
      <h3 className="our-clients__hundo">
        <span className="our-clients__hundo-fig">
          {isJsEnabled ? <Counter /> : <>100,000,000</>}+ ft<sup>2</sup>
        </span>
      </h3>
      {/* <h3 className="our-clients__header">for clients like:</h3> */}
    </div>
  );
}

const COUNT = 100000000;

/**
 * Count up on visibility.
 */
const Counter = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [count, setCount] = useState(0);

  useEffect(() => {
    let increaseCount: NodeJS.Timer | undefined = undefined;

    if (isVisible && !count) {
      increaseCount = setInterval(() => {
        setCount((count) => Math.min(Math.floor(count + COUNT / 17), COUNT));
      }, 124);
    }

    if (increaseCount && count === COUNT) {
      clearInterval(increaseCount);
    }

    return () => {
      if (increaseCount && count === COUNT) {
        clearInterval(increaseCount);
      }
    };
  }, [isVisible, count]);

  return (
    <VisiblitySensor onChange={(isVisible: boolean | ((prevState: boolean) => boolean)) => setIsVisible(isVisible)}>
      <span>{Number(count).toLocaleString()}</span>
    </VisiblitySensor>
  );
};
