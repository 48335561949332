import { styled } from "@mui/material";
import { breakpoint } from "../../components/styled-components/Breakpoints/breakpoints";

export const MainContainer = styled("div")`
  font-family: Helvetica;
  font-weight: 300;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0px;
  max-width: 1050px;
  margin: 0 auto;
  width: 100%;
  justify-content: center;

  ${breakpoint(
    "medium+",
    `
    min-height: calc(100vh - 60px); //vh - nav heights

  `
  )}

  ${breakpoint(
    "large-",
    `
    padding: 0 20px;
    width: 100%;
  `
  )}

  ${breakpoint(
    "small-",
    `
    width: 100%;
    padding: 0 20px;
    
    h2 {
      text-align: center;
    }
  `
  )}
`;

export const Wrapper = styled("div")`
  margin-top: 20px;
`;
