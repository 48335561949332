import React, { useRef, useEffect, useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { useIsJsEnabled } from "gatsby-plugin-js-fallback";
import VisiblitySensor from "react-visibility-sensor";
import CustomCarousel from "../page-components/testimonials/CustomCarousel/CustomCarousel";

export default function Testimonials(props: { className: any; }) {
  // Get all files related to services.
  const data = useStaticQuery(graphql`
    query AllTestimonials {
      allTestimonialsJson {
        nodes {
          id
          copy
          source
          src
        }
      }
    }
  `);

  const testimonials = data.allTestimonialsJson.nodes.map(
    ({ copy, source, id }: { copy: string; source: string[]; id: string }) => ({
      message: copy,
      author: source,
      id,
    })
  );
  const testimonialLogos = data.allTestimonialsJson.nodes.map(
    ({ src, id }: { src: string; id: string }) => ({
      src,
      id,
    })
  );

  // Handle slide up animation.
  const isJsEnabled = useIsJsEnabled();
  const hasAnimatedRef = useRef(false); // Only run uE hook once.
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (isVisible && !hasAnimatedRef.current) {
      hasAnimatedRef.current = true;
    }
  }, [isVisible]);

  let className = `testimonials ${props.className ?? ""}`;
  if (isJsEnabled && !isVisible && !hasAnimatedRef.current) {
    className = `${className} testimonials--hidden`;
  }

  return (
    <div className={className}>
      <VisiblitySensor onChange={(isVisible: boolean | ((prevState: boolean) => boolean)) => setIsVisible(isVisible)}>
        <div className="hidden-item hidden-item--absolute">&nbsp;</div>
      </VisiblitySensor>
      <CustomCarousel logo={testimonialLogos}>
        {testimonials.map(({ message, author, id }: { message: string; author: string[]; id: string }) => (
          <div className="testimonials__container" key={id}>
            <p
              className={`testimonials__copy ${
                message.length > 800 ? "testimonials__copy-long" : ""
              }`}
            >
              {`"${message}"`}
            </p>
            {author.map((text, i) => (
              <p
                className="testimonials__copy testimonials__copy--signature"
                key={i}
              >
                {i === 0 ? `- ${text}` : text}
              </p>
            ))}
          </div>
        ))}
      </CustomCarousel>
    </div>
  );
}
